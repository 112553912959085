.searchBox > .searchTxt{
    width: 240px;
    padding: 0 6px;
    border-bottom: 2px solid #757575;
}
.searchBox:hover > .searchBtn{
    background: white;
}
.searchBtn{
    color: #e84118;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    margin-left: 5px;
}

.searchTxt{
    border: none;
    color: #ACACAC;
    outline: none;
    float: left;
    padding: 0;
    font-size: 16px;
    transition: 0.4s;
    line-height: 30px;
    width: 0px;
}
.closeIconSearch{
    /* background-color: green; */
    margin-right: 5px;
    /* visibility: hidden; */
}

.searchIconClick{
    margin-top: 2px;
    height: 20px;
}
